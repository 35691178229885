<script>
  import Base from '@backend/Base.vue';
  import draggable from 'vuedraggable';
  import BOGen from '@helper/BOGen';
  export default {
    extends: Base,
    components: {
      draggable,
    },
    data() {
      return {
        Name: "ProjectAbout",
        row: {},
        row1: {},
        adv: {},
        fac: {},
        spc: [],
        spec: {},
        facility: {},
        input: {},
      }
    },
    computed: {

    },
    mounted() {
      this.$set(this.$root, 'page', this)
      this.refreshData()
    },
    methods: {
      submitForm(e, callback) {
        if (e && e.btnLoading()) return;
        this.row.type = "editAbout"
        BOGen.apirest("/" + this.Name, this.row, (err, resp) => {
          if (e) e.btnUnloading()
          if (err) {
            if (err.status == 422) {
              Object.keys(err.responseJSON).forEach((k) => {
                $("[error=" + k + "]").html("<label class='error'>" + err.responseJSON[k][0] + "</label>")
              })
            }
            return
          }
          if (resp.error) {
            if (this.errorFormCallback) return this.errorFormCallback(resp);
            if (callback) return callback(resp);
            return Gen.info(resp.message, "danger")
          }
          if (resp.success) {
            if (this.successFormCallback) return this.successFormCallback(resp);
            if (callback) return callback(resp);
            setTimeout(() => {
              $('[href="#collapseExample"]').click();
              this.refreshData()
            }, 2000)
            return Gen.info(resp.message, "success", 1000).then(() => {
              this.$router.push({
                name: this.Name,
                params: {
                  id: this.$route.params.id
                }
              })
            })
          }
        }, "POST")
      },
      addFc() {
        this.spc.push({
          spc_title: '',
          spc_value: ''
        })
      },
      delFc(k) {
        this.spc.splice(k, 1)
      },
      submitFormSpc(e, callback) {
        if (e && e.btnLoading()) return;
        this.spec.type = 'spec'
        this.spec.data = this.spc
        this.spec.project = App.$route.params.id
        BOGen.apirest("/" + this.Name, this.spec, (err, resp) => {
          if (e) e.btnUnloading()
          if (err) {
            if (err.status == 422) {
              Object.keys(err.responseJSON).forEach((k) => {
                $("[error=" + k + "]").html("<label class='error'>" + err.responseJSON[k][0] + "</label>")
              })
            }
            return
          }
          if (resp.error) {
            if (this.errorFormCallback) return this.errorFormCallback(resp);
            if (callback) return callback(resp);
            return Gen.info(resp.message, "danger", 1000, '.info-fac')
          }
          if (resp.success) {
            if (this.successFormCallback) return this.successFormCallback(resp);
            if (callback) return callback(resp);
            setTimeout(() => {
              $('[href="#collapseAdv"]').click();
              this.refreshData()
            }, 2000)
            return Gen.info(resp.message, "success", 1000, '.info-fac').then(() => {
              this.$router.push({
                name: this.Name,
                params: {
                  id: this.$route.params.id
                }
              })
            })
          }
        }, "POST")
      },
      addFac() {
        this.facility.push({
          fac_title: '',
          fac_value: ''
        })
      },
      delFac(k) {
        this.facility.splice(k, 1)
      },
      submitFormFac(e, callback) {
        if (e && e.btnLoading()) return;
        this.input.type = 'facilities'
        this.input.dataFac = this.facility
        this.input.project = App.$route.params.id
        BOGen.apirest("/" + this.Name, this.input, (err, resp) => {
          if (e) e.btnUnloading()
          if (err) {
            if (err.status == 422) {
              Object.keys(err.responseJSON).forEach((k) => {
                $("[error=" + k + "]").html("<label class='error'>" + err.responseJSON[k][0] + "</label>")
              })
            }
            return
          }
          if (resp.error) {
            if (this.errorFormCallback) return this.errorFormCallback(resp);
            if (callback) return callback(resp);
            return Gen.info(resp.message, "danger", 1000, '.info-facilities')
          }
          if (resp.success) {
            if (this.successFormCallback) return this.successFormCallback(resp);
            if (callback) return callback(resp);
            setTimeout(() => {
              $('[href="#collapseAdv"]').click();
              this.refreshData()
            }, 2000)
            return Gen.info(resp.message, "success", 1000, '.info-facilities').then(() => {
              this.$router.push({
                name: this.Name,
                params: {
                  id: this.$route.params.id
                }
              })
            })
          }
        }, "POST")
      },
      submitFormHeader(e, callback) {
        if (e && e.btnLoading()) return;
        this.row1.type = 'header'
        this.row1.dataFac = this.facility
        this.row1.project = App.$route.params.id
        BOGen.apirest("/" + this.Name, this.row1, (err, resp) => {
          if (e) e.btnUnloading()
          if (err) {
            if (err.status == 422) {
              Object.keys(err.responseJSON).forEach((k) => {
                $("[error=" + k + "]").html("<label class='error'>" + err.responseJSON[k][0] + "</label>")
              })
            }
            return
          }
          if (resp.error) {
            if (this.errorFormCallback) return this.errorFormCallback(resp);
            if (callback) return callback(resp);
            return Gen.info(resp.message, "danger", 1000, '.info-header')
          }
          if (resp.success) {
            if (this.successFormCallback) return this.successFormCallback(resp);
            if (callback) return callback(resp);
            setTimeout(() => {
              $('[href="#collapseAdv"]').click();
              this.refreshData()
            }, 2000)
            return Gen.info(resp.message, "success", 1000, '.info-header').then(() => {
              this.$router.push({
                name: this.Name,
                params: {
                  id: this.$route.params.id
                }
              })
            })
          }
        }, "POST")
      },
    },
    watch: {
      'adv.awc_value'(v) {
        if (v == true) {
          this.adv.awc_value = 'Y'
          this.advCheck = false
        } else if (v == false) {
          this.adv.awc_value = 'N'
          this.advCheck = true
        }
        this.adv.type = "editAdventage"
        BOGen.apirest("/" + this.Name, this.adv, (err, resp) => {}, "POST")
      },
      'fac.awc_value'(v) {
        if (v == true) {
          this.fac.awc_value = 'Y'
          this.advCheck = false
        } else if (v == false) {
          this.fac.awc_value = 'N'
          this.advCheck = true
        }
        this.fac.type = "editFac"
        BOGen.apirest("/" + this.Name, this.fac, (err, resp) => {}, "POST")
      },
    }

  };
</script>
<template>
  <div class="container-fluid">
    <PageTitle></PageTitle>
    <div class="topnavBackoffice">
      <router-link :to="{name:'ProjectHome',params:{id:$route.params.id}}">Beranda</router-link>
      <router-link :to="{name:'ProjectProducts',params:{id:$route.params.id}}">Produk</router-link>
      <router-link class="active" :to="{name:'ProjectAbout',params:{id:$route.params.id}}">Tentang Kami</router-link>
      <a href="javascript:;" class="dropdown_bo">Artikel & Progres
        <ul class="dropdown_menu">
          <li>
            <router-link :to="{name:'ProjectNews',params:{id:$route.params.id}}">Artikel</router-link>
          </li>
          <li>
            <router-link :to="{name:'ProjectProgress',params:{id:$route.params.id}}">Perkembangan Proyek</router-link>
          </li>
        </ul>
      </a>
      <router-link :to="{name:'ProjectContact',params:{id:$route.params.id}}">Lokasi & Kontak</router-link>
    </div>

    <div class="row">
      <!-- Start Contact -->
      <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">Header Section</h5>
            <VForm @resp="submitFormHeader" method="post">
              <div class="row">
                <div class="col-md-12 info-header"></div>
                <div class="col-md-6">
                  <BoField name="sc_title" v-model="row1.sc_title" mandatory></BoField>
                </div>
                <div class="col-md-12">
                  <BoField name="sc_desc1" mandatory :label="'Content'">
                    <CKEditor name="sc_desc1" class="form-control" v-model="row1.sc_desc"></CKEditor>
                  </BoField>
                </div>
                <div class="col-md-12 text-right"><button type="submit"
                    class="btn btn-rounded btn-info btn-loading">Update Header</button></div>
              </div>
            </VForm>
          </div>
        </div>
      </div>
      <!-- End Contact -->
      <!-- Start Contact -->
      <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">ABOUT US</h5>
            <VForm @resp="submitForm" method="post">
              <div class="row">
                <div class="col-md-12 info"></div>
                <div class="col-md-6">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="border_image_group">
                        <BoField name="sc_background" mandatory :label="'Image 1'">
                          <Uploader :param="{thumbnail:true}" name="sc_background" v-model="row.sc_background"
                            type="about1" uploadType="cropping"></Uploader>
                        </BoField>
                        <BoField v-model="row.sc_alt1" name="sc_alt1" :label="'Image Alt'"></BoField>
                      </div>

                      <div class="border_image_group">
                        <BoField name="sc_background2" mandatory :label="'Image 2'">
                          <Uploader :param="{thumbnail:true}" name="sc_background2" v-model="row.sc_background2"
                            type="about2" uploadType="cropping"></Uploader>
                        </BoField>
                        <BoField v-model="row.sc_alt2" name="sc_alt2" :label="'Image Alt'"></BoField>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="border_image_group">
                        <BoField name="sc_background3" mandatory :label="'Image 3'">
                          <Uploader :param="{thumbnail:true}" name="sc_background3" v-model="row.sc_background3"
                            type="about2" uploadType="cropping"></Uploader>
                        </BoField>
                        <BoField v-model="row.sc_alt3" name="sc_alt3" :label="'Image Alt'"></BoField>
                      </div>
                      <div class="border_image_group">
                        <BoField name="sc_background4" mandatory :label="'Image 4'">
                          <Uploader :param="{thumbnail:true}" name="sc_background4" v-model="row.sc_background4"
                            type="about1" uploadType="cropping"></Uploader>
                        </BoField>
                        <BoField v-model="row.sc_alt4" name="sc_alt4" :label="'Image Alt'"></BoField>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <input type="hidden" name="type" value="editContact">
                  <BoField name="sc_title" v-model="row.sc_title" mandatory></BoField>
                  <BoField name="sc_desc" mandatory>
                    <CKEditor name="sc_desc" class="form-control" v-model="row.sc_desc"></CKEditor>
                  </BoField>
                </div>
                <div class="col-md-12 text-right"><button type="submit"
                    class="btn btn-rounded btn-info btn-loading">Update Content</button></div>
              </div>
            </VForm>
          </div>
        </div>
      </div>
      <!-- End Contact -->


      <!-- Show ADV -->
      <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">Show Advantages</h5>
            <div class="row">
              <div class="col-md-2 max_120">
                Show Section?
              </div>
              <div class="col-md-2">
                <div class="custom-control custom-checkbox mr-sm-2 mb-3">
                  <div class="row">
                    <radio name="awc_value" v-model="adv.awc_value" option="Y" :attr="validation('awc_value')">
                      Yes</radio>
                    <radio name="awc_value" v-model="adv.awc_value" option="N">
                      No</radio>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- End Show ADV -->

      <!-- Show Specification -->
      <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">Specification</h5>
            <VForm @resp="submitFormSpc" method="post">
              <div class="info-fac"></div>
              <div class="row" v-for="( sv, sk ) in spc" :key="sk">
                <div class="col-md-4">
                  <BoField :label="'Title'" name="spc_title" v-model="sv.spc_title"></BoField>
                </div>
                <div class="col-md-4">
                  <BoField :label="'Value'" name="spc_value" v-model="sv.spc_value"></BoField>
                </div>
                <div class="col-md-4" style="padding-top: 2.2%;">
                  <a href="javascript:;" @click="addFc" class="btn btn-info"><i class="icon icon-plus"></i></a>&nbsp;
                  <a href="javascript:;" @click="delFc(sk)" class="btn btn-danger"><i class="icon icon-trash"></i></a>
                </div>
              </div>
              <div class="col-md-12 text-right"><button type="submit"
                  class="btn btn-rounded btn-info btn-loading">Update Content</button></div>
            </VForm>
          </div>
        </div>
      </div>
      <!-- End Show Specification -->

      <!-- Show ADV -->
      <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">Show Facilities</h5>
            <div class="row">
              <div class="col-md-2 max_120">
                Show Section?
              </div>
              <div class="col-md-2">
                <div class="custom-control custom-checkbox mr-sm-2 mb-3">
                  <div class="row">
                      <radio name="awc_val" v-model="fac.awc_value" option="Y" :attr="validation('awc_value')">
                        Yes</radio>
                      <radio name="awc_val" v-model="fac.awc_value" option="N">
                        No</radio>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- End Show ADV -->



      <!-- Show Specification -->
      <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">Fasilitas Sekitar </h5>
            <VForm @resp="submitFormFac" method="post">
              <div class="info-facilities"></div>
              <div class="row" v-for="( vf, kf ) in facility" :key="kf">
                <div class="col-md-4">
                  <BoField :label="'Title'" name="spc_title" v-model="vf.fac_title"></BoField>
                </div>
                <div class="col-md-4">
                  <BoField name="fac_value" :label="'Value'">
                    <TagsInput name="fac_value" :placeholder="'Add Facilities'" style="display:block"
                      v-model="vf.fac_value" :attr="validation('fac_value')"></TagsInput>
                  </BoField>
                </div>
                <div class="col-md-4" style="padding-top: 2.2%;">
                  <a href="javascript:;" @click="addFac" class="btn btn-info"><i class="icon icon-plus"></i></a>&nbsp;
                  <a href="javascript:;" @click="delFac(sk)" class="btn btn-danger"><i class="icon icon-trash"></i></a>
                </div>
              </div>
              <div class="col-md-12 text-right"><button type="submit"
                  class="btn btn-rounded btn-info btn-loading">Update Content</button></div>
            </VForm>
          </div>
        </div>
      </div>
      <!-- End Show Specification -->

    </div>
  </div>
</template>